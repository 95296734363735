import { Component, ElementRef, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Product } from 'src/app/books/models/product.model';
import { Language } from 'src/app/languages/models/language.model';
import { LanguageService } from 'src/app/languages/services/language.service';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import { Observable, map, startWith } from 'rxjs';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
// import { CategoryRegister } from '../../models/category-register.model';
import { CategoriesService } from '../../services/categories.service';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { LanguageTableContent, ValidAvailableLanguages } from 'src/app/books/models/edit-book.models';
import { CategoryTableContent, CategoryRegister } from 'src/app/books/models/category-product.models';
import { ResponseHelper } from 'src/app/shared/helpers/response.helper';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { AnyMxRecord } from 'dns';

interface CategoryData {
  [langCode: string]: {
    language: string;
    categoryName: string;
  };
}

interface CategoryNew {
    langCode: string;
    language: string;
    categoryName: string;
}

interface IntPrueba {
  name: string ; 

}
@Component({
  selector: 'app-create-categories',
  templateUrl: './create-categories.component.html',
  styleUrls: ['./create-categories.component.scss']
})
export class CreateCategoriesComponent {

  languages: Language[] = [];
  productsSelected: Product[] =[];
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  filteredProducts: Observable<Product[]>;
  productCtrl = new FormControl('');
  currentLang: string = ""; 
  cuerrentLanguageSelected: string = "";
  isUpdatingPrice: boolean = false;
  formSubmitted: boolean = false;
  validAvailableLanguages: any;

  languageDataSource = new MatTableDataSource<LanguageTableContent>([]);
  
  @ViewChild('categoryTableSort')
  categoryTableSort: MatSort;
  
  @ViewChild('categoryTable')
  categoryTable: MatTable<CategoryTableContent>;
  
  categoryDisplayedColumns: string[] = [];
  categoryDataSource = new MatTableDataSource<CategoryTableContent>([]);
  isUpdatingCategory: boolean = false;
  previousCategory: any;

  previousCategoryCountry: any;
  categoryForm: FormGroup;

  // shippingTableDisplayedColumns: string[] = ['index','weightTable_country', 
  // 'weightTable_weight', 'weightTable_unitWeight', 'weightTable_length', 'weightTable_height', 'weightTable_width', 'weightTable_unitLength','weightTable_price', 'actions'];
  // shippingTableDataSource = new MatTableDataSource<Shipping_costs>([]);
  // isUpdatingShippingCosts: boolean = false;
  // previousShippingCosts: any;

  @ViewChild('productInput') productInput: ElementRef<HTMLInputElement>;

  currentLanguageCategory = {} as CategoryNew;

  readonly getLanguagesObserver = {
    next: (data: CommonResponse<any>) => this.getLanguagesNext(data),
    error: (error: CommonResponse<any>) => this.getLanguagesError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  constructor(
    public dialogRef: MatDialogRef<CreateCategoriesComponent>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private _formBuilder: FormBuilder,
    private _translateService: TranslateService,
    private _languageService: LanguageService,
    private _categoriesService: CategoriesService,
    private _pageLoadingService: PageLoadingService,
    private _snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    this._pageLoadingService.showLoadingGif();

    this.currentLang =this._translateService.currentLang;

    this._translateService.onLangChange.subscribe(() => {
      this.currentLang =this._translateService.currentLang;
    });

    this._languageService.getAllLanguages().subscribe(this.getLanguagesObserver);

    this.categoryForm = this._formBuilder.group({
      'categoryName': new FormControl('', [Validators.required, Validators.min(0)]),
      'language': new FormControl('', [Validators.required, Validators.min(0)]),
      'status': new FormControl('', [Validators.required])
    });
  }

  ngOnInit() {
    this.initializeAvailableLanguages();
    //  this.categoryDataSource.sortingDataAccessor = this.sortinPriceAccessor;
  }
    
  ngAfterViewInit() {
    this.categoryDisplayedColumns = ['index', 'language', 'categoryName', 'actions'];
      // Forzar la detección de cambios
    this.cdr.detectChanges();
  }

  initializeAvailableLanguages() {
    this._languageService.getAllLanguages().subscribe(data => {
      if (ResponseHelper.responseDontHaveErrors(data)) {
        this.validAvailableLanguages = data.data;                
        
      }
      else {
        
      }
    });
  }

  validateLanguagesData() {
    let isSomeLanguageNotContained = false;

    this.validAvailableLanguages.forEach((validElement: any) => {
      this.languageDataSource.data.some((sourceElement) => {
        if (validElement.languageCode != sourceElement.language.languageCode)
          isSomeLanguageNotContained = true;
      });
    });
  }


  sortinPriceAccessor = (item: { [key: string]: any }, property: string) => {
    switch (property) {
      case 'language': return (item as CategoryTableContent).languageName;
      default: return item[property];
    }
  };

  addCategoryData() {
    
    
    this.formSubmitted = true;
    const isValidCategoryForm = this.validateFormData(this.categoryForm);
    

    if (!isValidCategoryForm)
      return;

    const categoryData: CategoryNew = {
      langCode: this.categoryForm?.get('language')?.value.languageIsoCode,
      language: this.categoryForm?.get('language')?.value.name,
      categoryName: this.categoryForm?.get('categoryName')?.value,
    };

    

    // const categoryData: CategoryNew = {
    //   [this.cuerrentLanguageSelected[0]["languageIsoC"]]: {
    //      language: this.categoryForm?.get('language')?.value,
    //      categoryName: this.categoryForm?.get('name')?.value,
    //    }
    // };
    
    if (!this.isCategoryFilled(this.categoryForm?.get('language')?.value.name)) {
      const addCategoryFunction = () => {
        const newCategoryData: CategoryTableContent = {
          index: this.categoryDataSource.data.length,
          languageIsoCode: this.categoryForm?.get('language')?.value.languageIsoCode,
          languageName: this.categoryForm?.get('language')?.value.name,
          categoryName: this.categoryForm?.get('categoryName')?.value,
          languageObject: this.categoryForm?.get('language')?.value
        };

        this.categoryDataSource.data.push(newCategoryData);
        this.categoryTable.renderRows();

        this.categoryForm?.get('language')?.setValue('default');
        this.categoryForm?.get('categoryName')?.setValue('');
        this.removeInvalidStyleFromForm(this.categoryForm);
      };

      this.executeTableAction(this.categoryDataSource, this.categoryTableSort, addCategoryFunction);
    }
    else {
      this._snackbarService.openStandardSnackBar('addCategoryData', 'Ok');
    }
  }

  editCategoryData() {
    const isValidCategoryForm = this.validateFormData(this.categoryForm);

    if (!isValidCategoryForm)
      return;

    const selectedCategoryLanguage = this.categoryForm?.get('language')?.value.name;
    
    
    
    

    const isValidEdit = !this.isCategoryFilled(selectedCategoryLanguage) ||
      this.previousCategory.languageName == selectedCategoryLanguage;

    
      
    if (isValidEdit) {
      const editCategoryFunction = () => {
        let indexToUpdate = this.categoryForm?.get('index')?.value;
        
        
        

        // Function to find the index based on language name
      
        for (let i = 0; i < this.categoryDataSource.data.length; i++) {
          if (this.categoryDataSource.data[i].languageName === selectedCategoryLanguage) {
            indexToUpdate = this.categoryDataSource.data[i].index;
          }
        }
  
        


        this.categoryDataSource.data[indexToUpdate].categoryName = this.categoryForm?.get('categoryName')?.value;
        this.categoryDataSource.data[indexToUpdate].languageName = this.categoryForm?.get('language')?.value.name;
        this.categoryDataSource.data[indexToUpdate].languageObject = this.categoryForm?.get('language')?.value;

        this.discardCategoryEdit();
      };

      this.executeTableAction(this.categoryDataSource, this.categoryTableSort, editCategoryFunction);
    }
    else {
      this._snackbarService.openStandardSnackBar('editCategoryData', 'Ok');
    }
  }

  setCategoryFormToEdit(index: number) {
    
    
    this.isUpdatingCategory = true;
    this.removeInvalidStyleFromForm(this.categoryForm);

    const indexToUpdate = this.categoryDataSource.data.findIndex(x => x.index == index);
    
    
    const elementToUpdate = this.categoryDataSource.data[indexToUpdate];
    

    this.categoryForm?.get('index')?.setValue(elementToUpdate.index);
    this.categoryForm?.get('categoryName')?.setValue(elementToUpdate.categoryName);
    this.categoryForm?.get('language')?.setValue(elementToUpdate.languageObject);

    this.previousCategory = elementToUpdate;
  }

  discardCategoryEdit() {
    
    
    this.isUpdatingCategory = false;

    this.categoryForm?.get('index')?.setValue('');
    this.categoryForm?.get('language')?.setValue('default');
    this.categoryForm?.get('categoryName')?.setValue('');

    this.removeInvalidStyleFromForm(this.categoryForm);
  }

  isCategoryFilled(language: string) {
    return this.categoryDataSource.data.some(x => {
      return x.languageObject.name === language;
    });
  }

  validateFormData(formGroup: FormGroup) {
    Object.entries(formGroup.controls).forEach(([key, value]) => {

      const controlElement = document.querySelector(`[formControlName=${key}]`);

      if ((value as AbstractControl).status == 'INVALID')
        controlElement?.classList.add('is-invalid');
      else
        controlElement?.classList.remove('is-invalid');
    });


    return formGroup.status == 'VALID';
  }

  private executeTableAction(dataSource: MatTableDataSource<any>, dataSort: MatSort, action: any) {
    /*Reason: The table wasn't refreshing when data source changing. It was caused by sort. By this reson we should
      disable the sort before update the table info, and before enable the sort again
    */
    this.disableDataSort(dataSource);

    action();

    this.enableDataSort(dataSource, dataSort);
  }

  private enableDataSort(dataSource: MatTableDataSource<any>, dataSort: MatSort) {
    dataSource.sort = dataSort;
  }

  private disableDataSort(dataSource: MatTableDataSource<any>) {
    dataSource.sort = null;
  }

  removeInvalidStyleFromForm(formGroup: FormGroup) {
    Object.entries(formGroup.controls).forEach(([key, value]) => {

      const controlElement = document.querySelector(`[formControlName=${key}]`);
      controlElement?.classList.remove('is-invalid');
    });
  }

  showCurrentLanguage(language: any) {
    
    
    this.cuerrentLanguageSelected = language;
    
    
    this.categoryForm?.get('amount')?.setValue('');
    this.categoryForm?.get('taxes')?.setValue('');
  }

  modalInteractTrue() {
    this.dialogRef.close(true);
  }

  getLanguagesNext(data: CommonResponse<any>) {
    this.languages = data.data;
  }

  getLanguagesError(error: CommonResponse<any>) {

  }

  onSubmit() {

    if(this.categoryDataSource.data.length != this.validAvailableLanguages.length) {
      return
    }
    

    // Crear objeto additionalData vacío
    const additionalData: CategoryData = {};

    // Iterar sobre cada elemento en categoryDataSource.data
    this.categoryDataSource.data.forEach((item: any) => {
      // Construir la estructura de additionalData para cada elemento
      additionalData[item.languageIsoCode] = {
          language: item.languageName,
          categoryName: item.categoryName
      };
    });

    const newCategoryRequest: CategoryRegister = {
      status: this.categoryForm.get("status")?.value,
      additionalData: additionalData,
    };

    // Validar si la clave "es" existe en additionalData
    if ("es" in newCategoryRequest.additionalData) {
      // Modificar categoryName si la clave "en" está presente
      newCategoryRequest.additionalData["es"].language = "Spanish";
    }

    

    this._categoriesService.createNewCategory(newCategoryRequest)
      .subscribe(this.createNewUserObserver);
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(true)
  }

  readonly createNewUserObserver = {
    next: (data: CommonResponse<any>) => this.createNewUserNext(data),
    error: (error: CommonResponse<any>) => this.createNewUserError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  createNewUserNext(data: CommonResponse<any>) {
    if(data.statusCode >= 200 && data.statusCode < 300) {
      window.location.reload()
    }
  }

  createNewUserError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    this._snackbarService.openStandardSnackBar(error.errors[0]);
  }

  add(event: MatChipInputEvent): void {
    // Clear the input value
    event.chipInput!.clear();
    this.productCtrl.setValue(null);
  }

  /** 
   * Eliminar un producto del selector de productos añadidos
   * @param product  elemento a eliminar del array
   */
  remove(product: any): void {
    const index = this.productsSelected.indexOf(product);

    if (index >= 0) {
      this.productsSelected.splice(index, 1);
    }
  }

  /** 
   * Seleccionar un producto del selector de productos a añadir
   * @param event  evento de tipo object con la información de la opción seleccionada
   */
  selected(event: MatAutocompleteSelectedEvent): void {
    //this.products.push(event.option.value);

    if (!this.productsSelected.includes(event.option.value)) {
      this.productsSelected.push(event.option.value);
      this.productsSelected[this.productsSelected.length-1].quantity = this.productsSelected[this.productsSelected.length-1].availability > 0 ? 1 : 0;
    }

    this.productInput.nativeElement.value = '';

    this.productCtrl.setValue(null);
  }


  

}
